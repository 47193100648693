import React from "react";
import parse from "html-react-parser";
import { fetchAppInfo } from "../../network/service";
import { useEffect } from "react";
import { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import Loading from "../../Components/Loading/Loading";


const PrivacyPolicy = () => {
  const [loading, setLoading] = useState(true);

  const [data, setData] = useState("");
  useEffect(() => {
    window.scroll(0,0)
    fetchPrivacyPolicyData();
  }, []);
  const fetchPrivacyPolicyData = async () => {
    setLoading(true)
    try{
      const params = {
        type: "PRIVACY_POLICY",
      };
      const response = await fetchAppInfo(params);
      if (response?.status === 200) {
        setData(response?.data?.data?.value);
        setLoading(false)

      }
    }catch(err){
      setLoading(false)
      toast.error(err?.response?.data?.message, {
        position: "bottom-center",
      });
    }
   
  };

  if(loading){
    return(
      <Loading/>
    )
  }

  return (
    <div className="privacyPolicy">
      <ToastContainer/>

      <div className="wrapper"> { data ? parse(data):<h1 className='noData'>NO DATA</h1>}</div>
      {/* <div dangerouslySetInnerHTML={{__html: '<strong>strong text</strong>'}} /> */}
    </div>
  );
};

export default PrivacyPolicy;
