import React from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { paypalSubscription, stripeDecode, updatePayment } from "./service";

const PaymentSuccess = () => {
  const projectInfo = useSelector((state) => state?.projectInfo?.value);
  const accessToken = useSelector((state) => state?.accessToken?.value);
  const appInfo = {
    projectDetails: projectInfo,
    accessToken: accessToken,
  };
  const navigate = useNavigate();
  const urlParams = new URLSearchParams(window.location.search);
  useEffect(() => {
    const sessionId = urlParams.get("session_id");
    const paypalData = urlParams.get("token")
      ? urlParams.get("token")
      : urlParams.get("amt");
    if (sessionId) {
      fetchStripeDecode(sessionId)
    } else if (paypalData) {
      let modeOfPayment = "paypal"
      updatePaymentApi(paypalData, modeOfPayment)
    }
  }, []);

  const paypalSusbscriptionApi = () => {
    const subID = localStorage.getItem("selectedSubId");

    let myInterval = setInterval(async () => {
      const response = await paypalSubscription(appInfo, subID);
      if (response?.status === 201) {
        clearInterval(myInterval);
        const isAndroid = localStorage.getItem("isAndroid");
        if (isAndroid == "true") {
          window.location.href = process.env.REACT_APP_WEB_VIEW_SUCCESS;// android succes redirect
        } else {
          // setIsLoading(false);
          // setIsSucces(true);
        }
      }
    }, 10 * 1000)
  }

  const updatePaymentApi = async (subscription, modeOfPayment) => {
    const updatePaymentResponse = await updatePayment(appInfo, subscription, modeOfPayment, "success");
    if (updatePaymentResponse?.status === 200) {
      localStorage.removeItem("couponId");
      const isAndroid = localStorage.getItem("isAndroid");
      const iosSignup = localStorage.getItem("iosSignup");

      if (isAndroid == "true") {
        window.location.href = window.location.origin + "/webviewsuccess";  // android success redirect
      } else if (iosSignup == 'true') {
        localStorage.removeItem("iosSignup");
        setTimeout(() => {
          window.location.href = window.location.origin + "/ios-webview-success?key=" + appInfo?.accessToken;  // ios success redirect
        }, 2000);
      } else {
        // setIsLoading(false);
        // setIsSucces(true);
      }

    } else {
      const response = await updatePayment(appInfo, subscription, modeOfPayment, "failed")
      if (response?.status === 200) {
        const isAndroid = localStorage.getItem("isAndroid");
        if (isAndroid == "true") {
          window.location.href = window.location.origin + "/webviewfailed";; // android error redirect
        } else {
          navigate("/payment-failed")
        }
      }
    }
  }

  const fetchStripeDecode = async (sessionId) => {
    const stripeDecodeResponse = await stripeDecode(appInfo, sessionId)
    if (stripeDecodeResponse?.status === 200) {
      const subscription = stripeDecodeResponse?.data?.data?.subscription;
      let modeOfPayment = 'stripe'
      updatePaymentApi(subscription, modeOfPayment)
    }
  }

  const handleClick = () => {
    const showId = localStorage.getItem("showId");
    const eventId = localStorage.getItem("eventId");
    const vanityUrl = localStorage.getItem("vanityUrl")
    const isAndroid = localStorage.getItem("isAndroid")
    const iosSignup = localStorage.getItem("iosSignup")

    if (isAndroid == "true") {
      window.location.href = window.location.origin + "/webviewsuccess";  // android success redirect
    } else if (iosSignup == 'true') {
      localStorage.removeItem("iosSignup");
      setTimeout(() => {
        window.location.href = window.location.origin + "/ios-webview-success?key=" + appInfo?.accessToken;  // ios success redirect
      }, 2000);
    } else if (eventId) {
      navigate(`/live`, { state: { eventId: eventId } })
    } else {
      navigate(`/movies/${vanityUrl}`, { state: { showId } })
    }
  }

  return (
    <div className="success">
      <div className="successContainer">
        <div className="checkIcon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="92"
            height="92"
            fill="#fff"
            className="bi bi-check2"
            viewBox="0 0 16 16"
          >
            <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
          </svg>
        </div>
        <h5>Success</h5>
        <p>Your payment has successfully completed</p>
        <button onClick={() => handleClick()}>OK</button>
      </div>
    </div>
  );
};

export default PaymentSuccess;
