import React, { useEffect, useState } from "react";
import { ReactComponent as PlayIcon } from "../../../assets/Icons/play.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import { useDispatch ,useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "swiper/css/effect-fade";
import "swiper/css/autoplay";
import 'swiper/css/pagination';
import { ReactComponent as PremiumIcon } from "../../../assets/Icons/premium.svg";
import { ReactComponent as AddListIcon} from "../../../assets/Icons/addToWatchlist.svg";
import { updateWatchlistData } from "../../../network/service";
import { getMyListData } from "../../../Redux/MyList/MyListSlice";
// import { appInfo} from "../service";
import { EffectFade, Thumbs, Autoplay,Pagination } from "swiper";
import PlayerShowCard from "../../../Components/PlayerShowCard/PlayerShowCard";
import { fetchWatchlistShows } from "../../../Screens/MyList/service";

const Featured = ({ data }) => {

  const dispatch = useDispatch();
  const [watchlistStatus, setWatchlistStatus] = useState();
  const navigate = useNavigate();
  const user = useSelector((state) => state?.user?.value);
  const projectInfo = useSelector((state) => state?.projectInfo?.value);
  const accessToken = useSelector((state) => state?.accessToken?.value);
  
  const appInfo = {
    projectDetails: projectInfo,
    accessToken: accessToken,
  };
  const fetchWatchlist = async () => {
    const response = await fetchWatchlistShows(appInfo);
    if (response?.status === 200) {
      dispatch(
        getMyListData({
          myList: response?.data?.data,
        })
      );
    }
  };
  const updateWatchlist = async (showId, flag) => {
    try {
      const response = await updateWatchlistData(appInfo, showId, flag);

      if (response?.status === 200) {
        fetchWatchlist();
        if (flag === 1) {
         ;
          setWatchlistStatus("added");
        } else {
          setWatchlistStatus("removed");
        }
      }
    } catch (err) {
      console.log(err);
    }
  };
  const addToWatchlist = (showId) => {
    if (user) {
      updateWatchlist(showId, 1);
    } else {
      navigate("/login");
    }
  };

  return (
    <div className="featured">
      <Swiper
        slidesPerView={1}
        watchSlidesProgress
        effect={"fade"}
        spaceBetween={0}
        loop={false}
        pagination={{ clickable: true }}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        
        className="mySwiper"
        modules={[EffectFade, Autoplay,Pagination]}
        // allowSlideNext={false}
        // allowSlidePrev={false}
      >
        {data?.shows?.map((item, index) => (
          <SwiperSlide key={index}>
           <PlayerShowCard data={item}/>
          </SwiperSlide>
        ))}     
      </Swiper>
      {/* <div className="addToWatchList"  onClick={() => addToWatchlist(data?.show_id)} title="Add to WatchList">
          <AddListIcon/>

      </div> */}
     
    </div>
  );
};

export default Featured;
