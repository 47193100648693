import React, { useEffect, useState } from 'react'
import { fetchAppInfo } from '../../network/service';
import parse from "html-react-parser";
import Loading from '../../Components/Loading/Loading';
import { ToastContainer, toast } from "react-toastify";


const About = () => {
  const [data, setData] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    window.scroll(0,0)
    fetchAboutUs();
  }, []);

  const fetchAboutUs = async () => {
    setLoading(true)
    try{
      const params = {
        type: "ABOUT_US",
      };
      const response = await fetchAppInfo(params);
     
      if (response?.status === 200) {
        setData(response?.data?.data?.value);
        setLoading(false);

      }
    }catch(err){
      setLoading(false);
      toast.error(err?.response?.data?.message, {
        position: "bottom-center",
      });
    }
    
  };

  if(loading){
    return(
      <Loading/>
    )
  }

  return (
    <div className="aboutUs">
      <ToastContainer/>
      <div className="wrapper"> { data ? parse(data):<h1 className='noData'>NO DATA</h1>}</div>
      {/* <div dangerouslySetInnerHTML={{__html: '<strong>strong text</strong>'}} /> */}
    </div>
  )
}

export default About
