import React, { useEffect } from 'react'
import TvActivationReverse from './TvActivationReverse'
import { useSelector } from 'react-redux';
import LinkTvApp from './LinkTvApp';

const TVAppIndex = () => {
    const projectInfo = useSelector((state) => state?.projectInfo?.value);

    useEffect(() => {
        window.scroll(0, 0)

    }, [])
    return (
        <div className="tvAppIndex">
            {projectInfo?.projectConfig?.config?.REVERSE_TV_CODE_FLOW === "true" ? (
                <TvActivationReverse />

            ) : (
                <LinkTvApp />
            )
            }
        </div>
    )
}

export default TVAppIndex