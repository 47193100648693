import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Loading from "../../Components/Loading/Loading";
import ShowCard from "../../Components/ShowCard/ShowCard";
import { ToastContainer, toast } from "react-toastify";
import { ReactComponent as AddToWatchListButton } from "../../assets/Icons/addToWatchlist.svg";
import { useWindowWidth } from "@react-hook/window-size";

import * as service from "./service";
import { rowItemCount } from "../../utils/utils";
const MyList = () => {
  const projectInfo = useSelector((state) => state?.projectInfo?.value);
  const accessToken = useSelector((state) => state?.accessToken?.value);
  const myListRedux = useSelector((state) => state?.myList?.value);
  const [shows, setShows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [publisherName,setPublisherName] = useState("")
  const [thumbnailOrientation, setThumbnailOrientation] = useState("");
  const [rowItemsClass,setRowItemsClass] = useState("")
  const windowSize = useWindowWidth()
  const appInfo = {
    projectDetails: projectInfo,
    accessToken: accessToken,
  };
  useEffect(() => {
    fetchWatchlistShows();
  }, []);

  useEffect(() => {
    if (myListRedux) {
      setShows(myListRedux);
    }
  }, [myListRedux]);

  useEffect(() => {
    if (projectInfo) {
      setPublisherName(projectInfo?.projectConfig?.config?.TAB_TITLE)
    }
  }, [projectInfo]);

  useEffect(() => {
    if(windowSize>980){
      if(projectInfo?.projectConfig?.config?.WEBSITE_ROW_ITEM_COUNT){
        setRowItemsClass(rowItemCount(projectInfo?.projectConfig?.config?.WEBSITE_ROW_ITEM_COUNT))
      }else{
        setRowItemsClass("rowCount6")
      }
    }else{
      setRowItemsClass("rowCount6")
    }
  }, [projectInfo,windowSize]);
  const fetchWatchlistShows = async () => {
    try {
      const response = await service.fetchWatchlistShows(appInfo);
      if (response?.status === 200) {
        setShows(response?.data?.data);
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      toast.error(err?.response?.data?.message, {
        position: "bottom-center",
      });
    }
  };

  if (loading) {
    return <Loading />;
  }
  return (
    <div className="myList">
      <ToastContainer />

      <div className="wrapper">
        <div className="top">
          <h1 className="heading">MY LIST</h1>
        </div>
        <div className="bottom">
          {
            shows?.length===0&&
          <div className="noContentInfo">
            <h4 className="heading">Welcome to your My List</h4>
            <p>
              You can add any film that you want to watch. Just search for it,
              and hit the <AddToWatchListButton /> button. When they are
              available on {publisherName}, they’ll appear right here. So you won’t miss
              them. Magic.
            </p>
          </div>
          }
          <div className="itemsContainer">
            {shows?.map((item, index) => (
              <div className={thumbnailOrientation===`PORTRAIT`?`items portrait ${rowItemsClass}`:`items landscape ${rowItemsClass}`} key={index}>
                <ShowCard
                  data={item}
                  key={index}
                  imageUrl={true}
                  season={false}
                  metaData={true}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyList;
