import React, { useEffect, useState } from "react";
import { imageUrlCheck } from "../../../utils/utils";

const CastAndCrew = ({ data ,type }) => {
  const [datas, setDatas] = useState([]);
  useEffect(() => {
    if (data) {
      setDatas(data);
    }
  }, []);


  return (
    <div className="castAndCrew">
      <h1 className="heading">{type}</h1>
      <ul className="lists">
        {datas?.map((item, index) => (
          <li className="list" key={index} onClick={()=>window.open(item?.imdb_link)}>
            {
                    item?.image &&
                  <div className="imageContainer">
                    {
                      imageUrlCheck(item?.image) === true?(
                        <img src={item?.image} alt="Image" />

                      ):(
                        <img src={`${process.env.REACT_APP_IMAGE_URL + item?.image}`} alt="Image" />

                      )
                    }
                  </div>
                  }
            <div className="info">
              <h1 className="name">{item?.name}</h1>
              <p className="role">{item?.role}</p>
            </div>
          </li>
        ))}
        
      </ul>
    </div>
  );
};

export default CastAndCrew;
